import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import commuteImage from '../../_styles/images/Commute.png';
import Star from '../../_styles/images/Star.png';
import VanGreen from '../../_styles/images/VanGreen.png';
import Insurance from '../../_styles/images/Insurance.png';
import Calendar from '../../_styles/images/Calendar.png';
import { DriverStatus } from './common/driver-status/DriverStatus';
import { getDriverStatus } from '../helper';
import analyticsService from '../../shared/analytics/analytics-service';


function DriverInformation(props) {
    const { driver, isSubscription } = props;
    const license = driver && driver.license ? "********" + driver.license.charAt(driver.license.length - 1) : "";
    const statusInfo = getDriverStatus(props.status, driver);

    useEffect(() => {
        if (driver && !driver.isDriver && statusInfo && statusInfo.status === 'N/A') {
            analyticsService.getPageName({'pagename': 'drivers license/become a driver'});
        }else if (driver && driver.isDriver && statusInfo &&  statusInfo.status === 'approved' ) {
            analyticsService.getPageName({'pagename': 'drivers license/view'});
        }
    }, []);

    function sendAnalytics() {
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": "Apply to be a driver"
            }
        });
    }

    function getButtonByStatus(status) {
        if (status === 'pending') {
            analyticsService.getPageName({'pagename': 'drivers license/pending'});
            return <div className='button-container'>
                <Link className="update-button disabled" to="">
                    Pending Approval
                </Link> </div>
        }
        if (status === 'expiring' || status === 'expired' || status === 'expiring today') {
            analyticsService.getPageName({'pagename': 'drivers license/expired'});
            return <div className='button-container'>
                <Link className="update-button renew" to="/myprofile/license/edit">
                    Renew license
                </Link>
            </div>
        }
        if (status === 'declined') {
            analyticsService.getPageName({'pagename': 'drivers license/declined'});
            return <div className='button-container'></div>
        }
        return <div className='button-container'><Link className="update-button" to="/myprofile/license/edit" onClick={() => props.passAnalyticsClickEvent("update license")}> 
            Update license
        </Link></div>

    }

    return (
        (driver && !driver.isDriver && statusInfo && statusInfo.status === 'N/A') ?
            <div className='driver-information' role='group'>
                <div className='header'>
                    Driver’s License
                </div>
                <div className='driver-image-container'>
                    <img src={commuteImage} alt="commute Image" />
                </div>
                <div className='main-content'>
                    <div className='driving-info'>
                        <img src={Star} alt="steering" />
                        <div className='content'>Be the star of your commute. Volunteer to split driving duties with your crew.</div>
                    </div>
                    <div className='driving-info'>
                        <img src={VanGreen} alt="steering" />
                        <div className='content'>Authorized drivers can use Commute vehicles for limited and local personal use at the discretion of your group.</div>
                    </div>
                    <div className='driving-info'>
                        <img src={Calendar} alt="steering" />
                        <div className='content'>Designate different days of the week and drive as often as you want.</div>
                    </div>
                    <div className='driving-info'>
                        <img src={Insurance} alt="steering" />
                        <div className='content'>Auto liability coverage and physical damage protection are included with the vehicle so you can drive with peace of mind.</div>
                    </div>
                </div>
                {
                    isSubscription ?
                        <div className='button-container'>
                            <Link className="update-button" to="/myprofile/applyToDrive" onClick={sendAnalytics}>
                                Apply to be a driver
                            </Link>
                        </div>
                        :
                        <div className='button-container'>
                            <Link className="update-button" to="/driver-application" onClick={sendAnalytics}>
                                Apply to be a driver
                            </Link>
                        </div>

                }

            </div>
            :
            <div className='driver-information' role='group'>
                <div className='header'>
                    Driver’s License
                </div>
                {(statusInfo && statusInfo.status !== 'approved') ?
                    <DriverStatus statusInfo={statusInfo} /> :
                    <div className='sub-heading'>
                        Thank you for driving in the vanpool!
                    </div>
                }
                <div className='main-content'>
                    <div className='driver-details'>
                        <div className='section'>
                            <div className='label'>License</div>
                            <div className='value'>{license}</div>
                        </div>
                        <div className='section'>
                            <div className='label'>State</div>
                            <div className='value'>{driver && driver.state}</div>
                        </div>
                    </div>
                </div>
                {statusInfo && getButtonByStatus(statusInfo.status)}
                {statusInfo && (statusInfo.status === 'expiring' || statusInfo.status === 'expired') && <div className='legal-info'>
                    By clicking “Renew License,” you confirm that you have renewed your license and consent to allow Commute with Enterprise to run your driving record to verify your driving status, per your authorization in the Vanpool Coordinator/Driver Agreement.
                </div>}
            </div>
    );
}

export default connect(null, null)(DriverInformation);
