import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import dateUtility from '../../shared/dateUtility';
import {Document,Page,pdfjs} from 'react-pdf/dist/esm/entry.webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Http from '../../shared/http-service';
import { saveAs } from 'file-saver';
import config from '../../appConfig';
import { Close } from '../../shared/icons';
import Download from '../../_styles/images/download-white.png';
import DownloadBlack from '../../_styles/images/downloadBlack.svg';
import ImageNotAvailable from '../../_styles/images/ImageNotAvailable.svg';
import analyticsService from '../../shared/analytics/analytics-service';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import { RestrictFrom } from '../../shared/permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_PRE_DELIVERY } from '../../shared/permissions/permissions';
import { ENTER_KEY_CODE } from '../../shared/helpers/accessibilityHelpers';

function VanpoolInformation(props) {
  const [pdfFile,updatePdfFile] = useState(null);
  const [numPages,setNumPages] = useState(null);
  const [active, setActive] = useState(false);
  const [loading, updateLoading] = useState(true);

  const { vanpool, rideDays, vehicleDetails, vanpoolStatus } = props;
  var makeModel = vehicleDetails.year +" "+vehicleDetails.make +" "+ vehicleDetails.model;
  var daysBefore = vehicleDetails.nextScheduledMaintenanceDate && dateUtility.checkDaysAgo(14,vehicleDetails.nextScheduledMaintenanceDate);
  async function downloadAndSaveVcc(download) {
    setActive(true);
    let response = await Http.getStatic(
      `${config.kongApiBaseUrl}/vanpools/vanpool/vcc`, { "Content-Type": "application/pdf" });
    if (response.ok) {
      const pdf = await response.blob();
      if (!download && pdf && pdf.type.indexOf('error') == -1) {
        const file = window.URL.createObjectURL(pdf);
        updatePdfFile(file)
        updateLoading(false)
      } else {
        saveAs(pdf, "agreement.pdf");
      }

    } else {
      updateLoading(false)
      props.showError();
    }
  };

  const onKeyDownVcc = (e) => {
    if (e.which === ENTER_KEY_CODE) {
      downloadAndSaveVcc();
    }
  };

  function onDocumentLoadSuccess({numPages: nextNumPages}) {
    setNumPages(nextNumPages);
}

useEffect(() => {
  if (active) {
    document.querySelector('.vcc-modal-container .modal').focus();
  }
}, [active]);


function openCloseModal() {
  setActive(!active);
}

  return (
    <div className="vanpool-information" role='group'>
      {vanpoolStatus === 'P' || vanpoolStatus === 'A' ? null : <div role='alert' className={`${ !daysBefore ? 'alert' : 'alert due' }`}>
        {
            daysBefore ? 
            <span>{'Maintenance Due'}</span> 
            :
            <span>{'Maintenance Complete'}</span>
        }
       
      </div>}
      <div className="vanpool-name">{vanpool.vanpoolName}</div>
      <div className="vanpool-type"> {makeModel}</div>
      {/* <div className="vanpool-desc">{vanpool.vanpoolName}</div> */}
      <div className="vanpool-image-container">
        <img src={vanpool.carClassImage} 
                  alt={vanpool.vehicleType || 'Vanpool vehicle'}
                  className={`${vanpoolStatus === 'P' || vanpoolStatus === 'A' ? 'image-opacity' : 'image'
                }`}
                  onError={e => {
                  e.currentTarget.src = ImageNotAvailable;
                  e.currentTarget.alt = 'Image not available';
                  e.currentTarget.className = "image-not-found";
                  }} />
        {/* <div className="vanpool-type"> {makeModel}</div> */}
      </div>
      <div className='details'>
      <div className="vanpool-license">
          <span className="key">License Plate</span>
          {
            vanpoolStatus === 'P' || vanpoolStatus === 'A' ? 
            <span className="value">{"Coming Soon!"}</span> :
            <span className="value">{vanpool.vehicleRegistrationNumber}</span>
          }
        </div>
        <div className="vanpool-license">
          <span className="key">Vanpool ID</span>
          <span className="value">{vanpool.vanpoolId}</span>
        </div>
      </div>
      <div className="commuteDays">
        <div className="heading">Days of Operation</div>
        <div className="oprational-days" role='group'>
          {vanpool?.operationalDays?.map(day => (
              <div
                role='text'
                day={day}
                key={day}
                disabled={true}
                aria-label={`${day} ${rideDays.includes(day) ? 'commuting' : 'not commuting'}`}
                className={`day-btn ${
                  rideDays.includes(day) ? 'active' : 'not-active'
                }`}
              >
                {day.substr(0, 3)}
              </div>
            ))}
        </div>
      </div>
      <RestrictFrom roles={[ PERMISSION_VANPOOL_STATUS_PRE_DELIVERY ]} required={false}>
        <div className='vcc' tabIndex={0} role='button'  onClick={() => {downloadAndSaveVcc(false);
            analyticsService.analyticsProcessEvent({
              "event": "vehicle_condition_checklist_download",
              "context": {
                "event_action": "vehicle condition checklist",
                "file-name":"agreement.pdf"
              }
            });
          }} onKeyDown={(e) => onKeyDownVcc(e)}>
          <img src={DownloadBlack} aria-hidden={true}/>
          Vehicle Condition Checklist
        </div>
      </RestrictFrom>
      { active && <div className="vcc-modal-container">
          <div className="modal" tabIndex={-1} role="dialog" aria-modal="true" aria-labelledby="modal-title">
            <div className="header">
              <span id="modal-title">Vehicle Condition Checklist</span>
              <span className="close" onClick={() => openCloseModal()} aria-label="Close modal">
              <Close />
              </span>
            </div>
            <div className="body">
              {loading ? <div className='pdf-loader'>Loading pdf.. </div> :
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} >
                  {Array.from(new Array(numPages),(el,index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} textlayer={false} renderAnnotationLayer={false}/>
                  ))}
                </Document>
              }
            </div>
            <div className="footer" onClick={() => downloadAndSaveVcc(true)} >
              <img src={Download} aria-hidden={true}/>
              <button className='download-button'>DOWNLOAD</button>
            </div>
          </div>
      </div>
      }
    </div>
  );
}

export default connect(null, null)(VanpoolInformation);
