import { getTextForImage, getVanpoolerName } from '../../helpers/vanHelpers';
import {TextImage} from '../text-image/TextImage';
import analyticsService from '../../../shared/analytics/analytics-service';

export function Vanpooler(props) {
  const { participant } = props;
  const { firstName, lastName, profileImage, employer } = participant || {};
  const roles = Object.keys(props.participant.roles).filter(key=>props.participant.roles[key]);
  const showRole = roles && roles.length > 0;
  //props.participant.roleName == 'Driver' || props.participant.roleName == 'Coordinator' ||  props.participant.roleName.includes("Driver") || props.participant.roleName.includes("Coordinator");

  function openDetails(participant){
    if(props.fromRoster) {
      analyticsService.analyticsProcessEvent({
        "event": "roster_profile_click",
        "context": {
          "event_action": "roster profile click"
        }
      });
      props.showParticipantDetails(participant)
    }
  }

  function onTabKeyDown(participant, e) {
    if (e.which === 13) {
      openDetails(participant);
    }
  }

  return (
    <div className='vanpooler-container' role='button' tabIndex={0} aria-label={`View details of ${getVanpoolerName(firstName, lastName)}`} onClick={() => openDetails(participant)}   onKeyDown={(e) => onTabKeyDown(participant,e)}>
      <div className='image' aria-hidden='true'>
        {profileImage ? <img src={profileImage}/> : <TextImage text={getTextForImage(firstName, lastName)}/>}
      </div>
      <div className='name'>{getVanpoolerName(firstName, lastName)}</div>
      <div className='company-role'>
        {showRole && <div className='type'>{roles.join().replace(/([a-z])([A-Z])/g, '$1 $2').replaceAll(","," / ")}</div>}
        <div className='company'>{employer}</div>
      </div>
    </div>
  );
}