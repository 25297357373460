import MainteanceAlert from './maintenanceAlert';
import RosterContainer from './rosterContainer';
import RequestNewVehicle from './requestNewVehicle';
import RouteMap from './route/routeMap';
import RouteStops from './route/routeStops';
import { connect } from 'react-redux';
import dateUtility from '../../shared/dateUtility';
import Maintenance from '../../_styles/images/new-icons/maintenance-green.png';
import ChevronRight from '../../_styles/images/new-icons/chevronright-dark.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function OtherDetails(props) {
  const { vehicleDetails, participants, route } = props;
  var daysBefore = vehicleDetails.nextScheduledMaintenanceDate && dateUtility.checkDaysAgo(14,vehicleDetails.nextScheduledMaintenanceDate);

  return (
    <div className="other-information" role='group'>
      { daysBefore ? 
        <MainteanceAlert vehicleDetails={vehicleDetails} history={props.history} profile={props.profile} vanpoolStatus={props.userAuth.vanpoolStatus} />
       :
        null }
      {
        participants.length ?
        <RosterContainer participants={participants} history={props.history} />
        :
        null
      }
      <div className='content-border'></div>
        <RouteMap  route={route}/>
      <div className='content-border'></div>
        <RouteStops route={route}/>
      <div className='content-border'></div>
        {props.userAuth.coordinatorStatus === 'Approved' && <RequestNewVehicle/>}
        <div className='content-border'></div>
      {!daysBefore && !(props.userAuth.vanpoolStatus === 'P' || props.userAuth.vanpoolStatus === 'A')? <div className = 'maintenance'>
        <Link className='link' to="/vanpool/maintenance" aria-label="Maintenance Tracker">
          <div className='title'>
            <img className='image' src={Maintenance} aria-hidden='true'/>
            <div className='tracker'>
              <div className='heading'>
                Maintenance Tracker
              </div>
              <div className='date'>
                Last completed on {dateUtility.parseDateAsMonthDayYear(vehicleDetails.lastPreventativeMaintenanceOdometerDate)} at {vehicleDetails.lastPreventativeMaintenanceOdometer} mi
              </div>
            </div>
          </div>
          <img className='chevron-right' src={ChevronRight} aria-hidden='true' alt="Navigate to Maintenance Tracker"/>
        </Link>
      </div>:null}
    </div>
  );
}

export function mapStateToProps(state) {
  return {
      userAuth: state.userAuth,
      profile: state.dashboard.profile
  }
}

export default connect(mapStateToProps,null)(OtherDetails);
