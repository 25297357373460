import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import ParticipantViewer from './NewparticipantViewer';
import ParticipantEditor from './NewParticipantEditor';
import { RestrictTo, isAnyPresent } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR,
  TRADITIONAL_COORDINATOR_PERMISSION
} from '../../shared/permissions/permissions';
import CommonToast from '../../shared/components/common-toast-message/CommonToast';

export class NewParticipantProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      loading: true,
      active: false,
      participant: null,
      toastOpen: false,
      toastData: {
        message: '',
        type: 'success'
      }
    };
  }

  componentDidMount() {
    if (this.props.participantId) {
      this.setState({
        participant: this.props.participants,
        loading: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.allParticipants && nextProps.allParticipants[this.state.participant.participantId]){
      var participant = nextProps.allParticipants[this.state.participant.participantId]
      this.setState({
        participant: participant,
        loading: false
      });
    }
    if(nextProps.participantUpdateToast) {
     var name = nextProps.participants.firstName;
      this.setState({ toastData: {
        message: `${name}'s commute details have been successfully updated`,
        type: 'success'}
      });
      this.setState({toastOpen: true});
    }
  }


  edit() {
    this.setState({ editing: true });
  }

  cancel() {
    this.setState({ editing: false });
  }

  save({
    toWorkStartIndex,
    toWorkEndIndex,
    toHomeStartIndex,
    toHomeEndIndex,
    rideDays,
    isNtdRecorder,
    isBillingViewer
  }) {
    this.cancel();
    const toWork = this.state.participant.outboundRoutePoints;
    const toHome = this.state.participant.inboundRoutePoints;

    this.setState({ loading: true });
    this.props.save({
      payload: {
        participantId: this.props.participantId,
        participantRouteProfileId: this.state.participant
          .participantRouteProfileId,
        routeId: this.state.participant.routeId,
        rideDays: rideDays,
        roles: { tripRecorder: isNtdRecorder, billingViewer: isBillingViewer },
        outboundStartStopRoutePoints: {
          startRoutePointId: toWork[toWorkStartIndex].routePointId,
          stopRoutePointId: toWork[toWorkEndIndex].routePointId
        },
        inboundStartStopRoutePoints: {
          startRoutePointId: toHome[toHomeStartIndex].routePointId,
          stopRoutePointId: toHome[toHomeEndIndex].routePointId
        }
      },
      participant: {
        ...this.state.participant,
        toWorkStart: toWork[toWorkStartIndex],
        toWorkEnd: toWork[toWorkEndIndex],
        toHomeStart: toHome[toHomeStartIndex],
        toHomeEnd: toHome[toHomeEndIndex],
        rideDays,
        roles: {
          ...this.state.participant.roles
        }
      }
    });
  }

  render() {
    const { participant, toastData, toastOpen } = this.state;

    if (!participant) {
      return null;
    }
    const isCoordinator = isAnyPresent([TRADITIONAL_COORDINATOR_PERMISSION]);
    return (
      <div>
        <div className={'participant-profile'}>

          {this.state.editing && isCoordinator ? (
            <ParticipantEditor
              {...participant}
              save={participant => this.save(participant)}
              cancel={() => this.cancel()}
            />
          ) : (
            <ParticipantViewer {...participant} edit={() => this.edit()} currentParticipantID={this.props.currentParticipantID}/>
          )}
        </div>
        <CommonToast message={toastData.message} type={toastData.type} isOpen={toastOpen} closeToast={() => this.setState({toastOpen:false})} timeOut={6000000} />
      </div>
    );
  }
}


function mapStateToProps(state) {
    return {
      allParticipants: state.participants,
      participantUpdateToast: state.roster.participantUpdateToast,
      currentParticipantID : state.userAuth.participantId
    };
  }


function mapDispatchToProps(dispatch, ownProps) {
  return {
    save(data) {
      dispatch({ type: actionTypes.PARTICIPANT_PROFILE_SAVING, data: data });
    },
    delete(id) {
      dispatch({ type: actionTypes.PARTICIPANT_PROFILE_DELETING, data: id });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewParticipantProfile);
