import React, { useEffect, useRef, useState } from 'react';
import sponsored from '../../_styles/images/new-icons/sponsored.png';
import CloseIcon from '../../_styles/images/close-icon.svg';

const SponsoredVanpool = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const handleTextClick = () => {
    setShowTooltip(!showTooltip);
  };

  const handleCloseClick = () => {
    setShowTooltip(false);
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef?.current?.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltip]);


  return (
    <div className="sponsored-container">
      <span>
        <img alt='sponsored vanpool icon' src={sponsored} role='button' />
        <span className="sponsored-text"
          onClick={handleTextClick}
        >
          SPONSORED VANPOOL
        </span>
      </span>

      {showTooltip && (
        <div className="tooltip" ref={tooltipRef}>
          <div className="tooltip-content">
            <span>
              All or some of the subscription cost of this vanpool is covered by the sponsor. Depending on your selections, your card will be charged the remaining amount of your subscription cost.
            </span>
            <img alt='close icon' src={CloseIcon} onClick={handleCloseClick} role='button' />
          </div>
          <div className="tooltip-arrow" />
        </div>
      )}
    </div>
  );
};

export default SponsoredVanpool;
