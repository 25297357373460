import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import analyticsService from '../../../../shared/analytics/analytics-service';
import { Vanpooler } from '../../vanpooler/Vanpooler';


function ThirdScreen(props) {

  const [participants, updateParticipants] = useState([]);

  useEffect(() => {
    analyticsService.getPageName({
      'pagename': "customer onboarding/meet your vanpool",
      'process': "customer onboarding"
    });
  }, []);

  useEffect(() => {
    if (props.selectedVan && props.selectedVan.participants) {
      updateParticipants(props.selectedVan.participants);
    }
  }, [props.selectedVan])
  return (
    <div className='third-screen screen-container'>
      <h1 className='heading'>
        Meet your vanpool
      </h1>
      <div className='content-container'>
        <div className='list'>
          <div className='vanpoolers' aria-live='polite' role='list'>
            {participants && participants.length > 0 ? (
              participants.map((participant) => (
                <Vanpooler key={participant.participantId} participant={participant} />
              ))
            ) : (
              <p>No participants to show yet.</p>
            )}
          </div>
          {
            props.isPredelivery && <p className='more-vanpoolers-text'>More vanpoolers will be joining soon!</p>
          }
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedVan: state.joinCommute.selectedVan
  }
}

export default connect(mapStateToProps, null)(ThirdScreen)