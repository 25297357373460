import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteDetails } from '../../common/route-details/RouteDetails';
import { isEqual } from 'lodash';
import { initialJoinCommuteState } from '../../JoinCommuteReducer';
import DisplayCard from '../display-card/DisplayCard';
import Tick from '../../../_styles/images/tick.svg';
import { getDayString, getFullPrice, getNextMonth } from '../../helpers/vanHelpers';
import Loading from '../../../shared/loading/Loading';
import { GET_PRORATED_AMOUNT, SAVE_SUBSCRIPTION_SUMMARY, SAVE_COMMUTE_PROFILE, APPLY_TO_SIGN_CONTRACT } from '../../../redux/actionTypes';
import { Link } from 'react-router-dom';
import ArrowLeft from '../../../_styles/images/ArrowLeft.png';
import InfoCircle from '../../../_styles/images/new-icons/driver-status/info_circle.svg';
import { assignPriceToCards } from '../../../shared/helpers/cardHelpers';
import { numberWithCommas } from '../../helpers/commonHelper';
import ImageNotAvailable from '../../../_styles/images/ImageNotAvailable.svg';
import analyticsService from '../../../shared/analytics/analytics-service';
import PriceDisclaimerPopup from '../../subscription/PriceDisclaimerPopup';
import Callable from '../../../shared/Callable';
import TaxesAccordion from '../../../payment/common/TaxesAccordion';
import RateAccordion from '../../../payment/common/RateAccordion';
import dateUtility from '../../../shared/dateUtility';
import { getDateObjFromString } from '../../../payment-history/helper';
import SponsoredVanpool from '../../helpers/sponsoredVanpool';

function FinalSummary(props) {

  const [selectedVan, updateSelectedvan] = useState({});
  const [vanCharge, updateVanCharge] = useState();
  const [isDriver, updateIsDriver] = useState(false);
  const [rideDays, updateRideDays] = useState([]);
  const [selectedDay, updateSelectedDay] = useState();
  const [buttonDisabled, updateButtonDisabled] = useState(true);
  const [cards, updateCards] = useState([]);
  const [checkBox, updateCheckBox] = useState(false);
  const [proratedAmount, updateProratedAmount] = useState(0);
  const [recurringChargeDate, updateRecurringChargeDate] = useState();
  const [showPDPopup, updatePDPopup] = useState(false);
  const [loading, updateLoading] = useState(false);

  useEffect(() => {
    if (isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    }
    analyticsService.pageInfo("add payment");
  }, [])

  useEffect(() => {
    if (props.joinCommuteData) {
      if (props.joinCommuteData.selectedVan) {
        updateSelectedvan(props.joinCommuteData.selectedVan);
        var rosterDays = props.joinCommuteData.userCommuteData.rosterDays;
        var ridedays = Object.keys(rosterDays).filter((d) => rosterDays[d]);
        updateRideDays(ridedays)
      }
      if (props.joinCommuteData.userCommuteData) {
        if (props.joinCommuteData.userCommuteData.startDate) {
          updateSelectedDay(props.joinCommuteData.userCommuteData.startDate);
        }
        if (props.joinCommuteData.userCommuteData.proratedAmount) {
          const amount = parseFloat(props.joinCommuteData.userCommuteData.proratedAmount);
          const fullAmount = getFullPrice(amount);
          updateProratedAmount(fullAmount);
        }
        if(props.joinCommuteData.userCommuteData.recurringPaymentStartDate) {
          const chargeDateObj = getDateObjFromString(props.joinCommuteData.userCommuteData.recurringPaymentStartDate);
          updateRecurringChargeDate(chargeDateObj);
        }
      }
      if (props.joinCommuteData.drivingScheduleData) {
        if (props.joinCommuteData.drivingScheduleData.isDesiredDriverRole) {
          updateIsDriver(true);
        } else {
          updateIsDriver(false);
        }
      }
      if(props.summaryData){
        const summaryTotal = getFullPrice(props.summaryData.totalAmount);
        updateVanCharge({...summaryTotal, rate: props.summaryData.subscriptionRate});
      }
    }
    analyticsService.pageInfo("add payment");
  }, [props.joinCommuteData, props.summaryData]);

  useEffect(() => {
    if(!props.isCardLoading){
      if (vanCharge && vanCharge.totalAmount !== undefined && props.joinCommuteData && props.joinCommuteData.payment && props.joinCommuteData.payment.cards && props.joinCommuteData.payment.cards.length > 0) {
        const tempCards = assignPriceToCards(vanCharge.totalAmount, props.joinCommuteData.payment.cards);
        updateCards(tempCards);
      }
    }
  }, [props.isChangedPlan, vanCharge, props.joinCommuteData, props.joinCommuteData.payment.cards, props.isCardLoading])

  useEffect(() => {
    if (checkBox) {
      updateButtonDisabled(false);
    } else {
      updateButtonDisabled(true);
    }
  }, [checkBox])

  function onContinue() {
    analyticsService.analyticsProcessEvent({
      "event": "payment_summary_click_continue",
      "context": {
        "event_action": "finish"
      }
    });
    props.saveSummary(selectedDay ? dateUtility.getDateStringFromDay(selectedDay) : null, props.history);
  }

  function toggleCheckBox() {
    updateCheckBox(!checkBox);
  }

  useEffect(() => {
    if (props.isCardLoading === false) {
      if(selectedDay){
        props.getProratedAmount(dateUtility.getDateStringFromDay(selectedDay), props.history);
      } else {
        props.getProratedAmount('', props.history);
      }
    }
  }, [props.isCardLoading])

  function onCheckKeyDown(e) {
    if (e.which === 13) {
      toggleCheckBox();
    }
  }

  useEffect(() => {
    if(props.isCardLoading || props.isLoadingProratedAmount || props.isSaving){
      updateLoading(true);
    } else {
      updateLoading(false);
    }
  }, [props.isCardLoading, props.isLoadingProratedAmount, props.isSaving])

  return (
    <div className='final-summary-container'>
      <Loading isLoading={loading} />
      <div className="final-summary-page">
        <div className='header'>
          <div className='backArrow'>
            <Link className="overview-link" onClick={props.history.goBack}>
              <img alt='go back' src={ArrowLeft}></img>
            </Link>
          </div>
          <div className='heading'>Payment Summary</div>
        </div>
        {props.isChangedPlan &&
          <div className='blue-notes' role='alert'>
            <div className='warning-icon'>
              <img className='info-circle' alt='information-icon' src={InfoCircle} aria-hidden='true' />
            </div>
            We’ve changed your plan to become a rider as you don’t currently qualify as a driver. Please check your new rates below.
          </div>}
        <div className="vanpool-summary">
          <div className="vanpool-summary-details">
            <div className="vanpool-Details">
              <div className="van-details" role='group'>
                <div className="van-class-details" role='group'>
                  <div className="van-class">{selectedVan.vehicleClass}</div>
                  <div className="van-model">{selectedVan.ymms}</div>
                </div>
                <div className="van-price-details" role='group'>
                  {vanCharge && <div className='price-group' aria-label={`${vanCharge.price} dollars and ${vanCharge.decimal} cents`}>
                    <div className="doller">{'$'}</div>
                    <div className="price">{numberWithCommas(vanCharge.price)}</div>
                    <div className="decimal">.{vanCharge.decimal}</div>
                  </div>}
                  <div className="sub">{"total per month"}</div>
                </div>
              </div>
              <RouteDetails vanpool={selectedVan} />
              <div className="rideDays">
                <ul>
                  {rideDays.length && rideDays.map(day => (<li key={day.substr(0, 3)} aria-label={day}> {day.substr(0, 3)} </li>))}
                </ul>
              </div>
            </div>
          </div>
          <div className="vanpool-summary-prices" role='group'>
          {selectedVan.isSubsidized && <div className="final-sponsored-container">
              <span>What’s Included?</span>
              <SponsoredVanpool />
            </div>}
            <RateAccordion
              isDesiredDriverRole={isDriver}
              charge={vanCharge ? vanCharge.rate : 0}
            />
            {props.summaryData && <TaxesAccordion
              taxes={props.summaryData.taxes}
              surcharges={props.summaryData.surcharges}
              total={parseFloat(props.summaryData.taxAmount) + parseFloat(props.summaryData.surchargeAmount)} />}
            <div className='bottom' role='group'>
              <div className='disclaimer' role='group'>
                <p>
                  Recurring subscription. <br />
                  Rates subject to change. <br />
                  <span onClick={() => updatePDPopup(true)}>Learn More</span>
                </p>
              </div>
              <div className='total'>
                <div className='label'>Total</div>
                {vanCharge && <span className='grp'>
                  <div className='price' aria-label={`${vanCharge.price} dollars and ${vanCharge.decimal} cents`}>
                    <div className='dollar'>$</div>
                    <div>{numberWithCommas(vanCharge.price)}</div>
                    <div className='dollar'>.{vanCharge.decimal}</div>
                  </div>
                  <div className='frequency'>per month</div>
                </span>}
              </div>
            </div>
          </div>
          <div className='condition' role='group'>
            {checkBox ?
              <div className='checkbox selected' aria-labelledby="condition" aria-checked='true' onClick={toggleCheckBox} onKeyDown={(e) => onCheckKeyDown(e)} role='checkbox' tabIndex={0}>
                <img src={Tick} aria-hidden='true' />
              </div>
              :
              <div className='checkbox' aria-checked='false' aria-labelledby="condition" onClick={toggleCheckBox} onKeyDown={(e) => onCheckKeyDown(e)} role='checkbox' tabIndex={0}></div>
            }
            <div className='text' id="condition" aria-hidden='true'>
              I agree to be enrolled in a recurring monthly subscription for the total amount above, subject to change. I understand I will be charged the prorated amount today for the first month, and thereafter, the monthly rate on the first day of each month until cancelled. Federal benefit cards will be charged each month according to its payment schedule. I understand I may cancel with 30 days’ notice online or by telephone at <Callable phone="800 VAN 4 WORK" />.
            </div>
          </div>
          <div className="vanpool-summary-image">
            <div className="van-Image">
              <div className={`${selectedVan.isPredelivery ? 'car-image-opacity' : 'car-image'}`}>
                <img src={selectedVan.carClassImage} onError={e => {
                  e.currentTarget.src = ImageNotAvailable;
                  e.currentTarget.className = "image-not-found";
                }} alt='van image' />
              </div>
            </div>
          </div>
          <div className="vanpool-summary-plan">
            <div className="van-datePicker" role='group'>
              {props.joinCommuteData.plan.noOfDays === 5 ?
                <div className='plan-heading'>Unlimited Plan</div>
                :
                <div className='plan-heading'>{props.joinCommuteData.plan.noOfDays}-Day Plan</div>
              }
              {
                !selectedVan.isPredelivery && selectedDay ?
                  <div className='date-container'>
                    <label className='sub' aria-label='Your Start Date'>Your Start Date*</label>
                    <label className='date'>{getDayString(selectedDay, true)}</label>
                  </div>
                  :
                  <div className='van-start-details'>
                    <div className='content'>Your start date will be displayed once the vanpool has formed</div>
                  </div>
              }
            </div>
          </div>
          <div className='vanpool-payment-details'>
            {
              <div className='pay-today' role='group'>
                <div className='sub-heading'>
                  <div className='sub'>Pay Today</div>
                  {!selectedVan.isPredelivery && selectedDay ?
                    (proratedAmount &&
                      <div className='price-group' aria-label={`${proratedAmount.price || '0'} dollars and ${proratedAmount.decimal || '00'} cents`}>
                        <div className="doller">{'$'}</div>
                        <div className="price">{numberWithCommas(proratedAmount.price) || '0'}</div>
                        <div className="decimal">.{proratedAmount.decimal || '00'}</div>
                      </div>)
                    :
                    <div className='price-group'>
                      <div className="doller">{'$'}</div>
                      <div className="price">{'0'}</div>
                      <div className="decimal">.{'00'}</div>
                    </div>}
                </div>
                {!selectedVan.isPredelivery && selectedDay ?
                  <div className='content'>Your pro-rated amount accounts for your start date through the end of this month</div>
                  :
                  <div className='content'>You may be charged the full or pro-rated amount based on when your van will form</div>
                }

              </div>}
            <div className='recurring-details' role='group'>
              <div className='sub-heading'>Auto Recurring Payment</div>
              {
                selectedVan.isSubsidized ? <div className='content'>{recurringChargeDate && <span className='bold'>Starting {recurringChargeDate.month} {recurringChargeDate.day}{recurringChargeDate.dateSuffix},</span>} Until cancelled, all or some of the subscription cost of this vanpool is covered by the sponsor and your card will be charged the remaining amount of your subscription cost, plus applicable fuel charges and taxes (may vary depending on the jurisdiction).</div> : (!selectedVan.isPredelivery && selectedDay ?
                  <div className='content'>{recurringChargeDate && <span className='bold'>Starting {recurringChargeDate.month} {recurringChargeDate.day}{recurringChargeDate.dateSuffix},</span>} You will be charged automatically each month for the total amount indicated below until cancelled. The monthly cost is subject to change based on factors like fuel costs and tax rates.</div>
                  :
                  <div className='content'>You will be charged automatically once the van has formed. The monthly cost may fluctuate slightly based on factors like fuel costs and tax rates.</div>)
              }

            </div>
          </div>
          <div className='card-list' role='group'>
            <div className='sub-heading'>
              <div>Payment Card</div>
              <div className='side'>
                Recurring charges
              </div>
            </div>
            <div className='card-list-container'>
              {cards &&
                cards.map(card => <DisplayCard key={card.cardNumber} card={card} />)}
            </div>
          </div>
        </div>
        {
          showPDPopup && <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
        }
        <div className='button-container'>
          <button className='continue-button' onClick={onContinue} disabled={buttonDisabled}>FINISH</button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    joinCommuteData: state.joinCommute,
    summaryData: state.joinCommute.userCommuteData && state.joinCommute.userCommuteData.summaryData,
    isCardLoading: state.joinCommute.payment.isCardSaving,
    isLoadingProratedAmount: state.joinCommute.payment.isLoadingProratedAmount,
    isSaving: state.joinCommute.payment.isSavingSubscription,
    isChangedPlan: state.joinCommute.drivingScheduleData.isChangedPlan
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getProratedAmount(startDate, history) {
      dispatch({
        type: GET_PRORATED_AMOUNT,
        startDate,
        history
      })
    },
    getDocusignContract(isDriver, vanpoolId, history) {
      dispatch({ type: APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history } })
    },
    saveSummary(startDate, history) {
      dispatch({
        type: SAVE_SUBSCRIPTION_SUMMARY,
        startDate,
        history
      })
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalSummary)