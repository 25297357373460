import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ArrowLeft from '../../../_styles/images/ArrowLeft.png';
import { useEffect, useRef, useState } from 'react';
import { getDaysFromPackage } from '../../../join-commute/helpers/vanHelpers';
import { getSortedCards, splitNumber } from '../../../payment/helper';
import { assignPriceToCards } from '../../../shared/helpers/cardHelpers';
import Calendar from '../../../shared/inputs/Calendar/Calendar';
import { addDays, getDaysFromRosterDays, getUpdatedRoosterDays } from '../../../join-commute/helpers/commonHelper';
import LeftSection from '../../../shared/components/summary/left-section/LeftSection';
import SummaryPaymentCards from '../../../shared/components/summary/payment-cards/SummaryPaymentCards';
import DatePickerCustom from '../../../shared/components/summary/date-picker/DatePickerCustom';
import Loading from '../../../shared/loading/Loading';
import dateUtility from '../../../shared/dateUtility';
import * as actionTypes from '../../../redux/actionTypes';
import ChangeSubscriptionConfirmation from './confirmation/ChangeSubscriptionConfirmation';
import DiscardChanges from '../common/discardChangesPopup/DiscardChanges';
import ImageNotAvailable from '../../../_styles/images/ImageNotAvailable.svg';
import PriceDisclaimerPopup from '../../../join-commute/subscription/PriceDisclaimerPopup';

function ChangeSubscriptionSummary(props) {

  const node = useRef(null);
  const navigationAlertNode = useRef(null);

  const [enabledDays, updateEnabledDays] = useState([]);
  const [cards, updateCards] = useState([]);
  const [van, updateVan] = useState();
  const [tax, updateTax] = useState();
  const [addressDetails, updateAddressDetails] = useState();
  const [selectedDay, updateSelectedDay] = useState();
  const [isOpen, updateIsOpen] = useState(false);
  const today = new Date(new Date());
  const [calendarInfo, updateCalendarInfo] = useState({
    startDay: today,
    endDay: addDays(30, today)
  });
  const [plan, updatePlan] = useState('');
  const [loading, updateLoading] = useState(false);
  const [subscription, updateSubscription] = useState({});
  const [showConfirmation, updateShowConfirmation] = useState(false);
  const [subscriptionStartDay, updateSubscriptionStartDay] = useState('');
  const [showPDPopup, updatePDPopup] = useState(false);

  function toggleCalendar() {
    if (!isOpen) {

      const modal = document.getElementById("modal");
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "true");
      updateIsOpen(true);
      if (modal) {
        modal.setAttribute("aria-modal", "true");
        modal.querySelector(".calendar-day").focus();
      }
    } else {
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }

  }


  function onCalendarKeydown(e) {
    if (e.which == 13) {
      toggleCalendar();
    }
  }

  function checkAndCloseCalendar() {
    const modal = document.getElementById("modal");
    const classes = Array.from(modal.classList);
    if (classes.includes('show')) {
      const all = document.querySelector(".vanpool-summary");
      all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }
  }

  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    toggleCalendar();
  }

  useEffect(() => {
    if (props.profile) {
      if (props.profile.paymentCards && subscription && subscription.totalAmount) {
        const sortedCards = getSortedCards(props.profile.paymentCards);
        const price = parseFloat(subscription.totalAmount);
        const chargedCards = assignPriceToCards(price, sortedCards);
        updateCards(chargedCards);
      }
      if (props.profile.commuteDetails) {
        updateAddressDetails(props.profile.commuteDetails);
      }
      if (props.profile.vanpool) {
        updateVan(props.profile.vanpool);
      }
    }
  }, [props.profile, subscription])


  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', clickOutside);
      return () => {
        document.removeEventListener('mousedown', clickOutside);
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (!props.selectedPlan) {
      props.history.goBack();
    } else {
      const days = getDaysFromPackage(props.selectedPlan.packageName);
      updatePlan(days);
      if (!props.selectedPlan.isPlanUpgrade) {
        const startDay = calendarInfo.endDay;
        const endDay = addDays(60, startDay);
        updateCalendarInfo({
          startDay,
          endDay
        })
      }
    }
  }, [props.selectedPlan])


  useEffect(() => {
    if (props.cancelling) {
      updateLoading(true);
    } else {
      updateLoading(false);
    }
  }, [props.cancelling])

  useEffect(() => {
    if (props.cancelled) {
      updateLoading(false);
    }
  }, [props.cancelled])

  useEffect(() => {
    if (props.selectedPlan) {
      if (props.selectedPlan.totalAmount) {
        const rate = props.selectedPlan.rate;
        const totalAmount = props.selectedPlan.totalAmount;
        const price = splitNumber(props.selectedPlan.totalAmount);
        updateSubscription({ ...props.selectedPlan, rate, totalAmount, ...price });
      }
      if (props.selectedPlan.days) {
        const runningDays = getDaysFromRosterDays(props.selectedPlan.days);
        updateEnabledDays(runningDays);
      }
    }
  }, [props.selectedPlan])

  function saveChangeSubscription() {
    const effectiveDate = dateUtility.getDateStringFromDay(selectedDay);
    const rosterDays = getUpdatedRoosterDays(props.selectedPlan.days, true);
    let data = {
      effectiveDate,
      packageDiscountType: subscription.role === 'driver' ? "Driver" : "Rider",
      packageName: props.selectedPlan.packageName,
      rosterDays,
      subscriptionType: "Fixed",
      agreementId: props.agreementId,
      clickwrapId: props.clickwrapId
    }
    if (props.selectedPlan && props.selectedPlan.isRoleChange && subscription.role === 'driver') {
      data = { ...data, driverRoleInformation: props.selectedPlan.driverRoleInformation }
    }
    const startSub = dateUtility.parseDateAsMonthDayYearFromGMT(selectedDay);
    updateSubscriptionStartDay(startSub);
    updateLoading(true);
    props.changeSubscription(data);
  }

  useEffect(() => {
    if (props.changed && props.changed === true) {
      updateLoading(false);
      updateShowConfirmation(true);
    } else if (props.changed === false) {
      props.changeSubscriptionReset();
      updateLoading(false);
      props.history.push('/myprofile');
    }
  }, [props.changed])

  function closeChangeSubscriptionPopup() {
    updateShowConfirmation(false);
    updateSubscriptionStartDay('');
    props.history.push('/myprofile');
  }

  useEffect(() => {
    if (!(props.selectedPlan && props.loaded)) {
      props.history.go(-1);
    }
    const close = (e) => {
      if (e.keyCode === 27) {
        const popup = document.getElementById('change-subscription-confirmation-popup');
        if (popup) {
          closeChangeSubscriptionPopup();
        }
        checkAndCloseCalendar();
      }
    }
    window.addEventListener('keydown', close)
    return () => {
      updateSubscriptionStartDay('');
      updateShowConfirmation(false);
      window.removeEventListener('keydown', close)
    }
  }, [])

  function goBack() {
    props.history.goBack();
  }

  return (
    <div className='final-summary-container cancel-summary-container change-subscription-container'>
      <Loading isLoading={loading} />
      <div className="final-summary-page cancel-summary-page summary-section">
        <div className='header'>
          <div className='backArrow'>
            <Link className="overview-link" onClick={goBack}>
              <img alt='go back' src={ArrowLeft}></img>
            </Link>
          </div>
          <div className='heading'>Summary</div>
        </div>
        {van && addressDetails &&
          <div className="vanpool-summary" role='group'>
            <LeftSection van={van} subscription={subscription} isDriver={subscription.role === 'driver'} tax={tax} addressDetails={addressDetails} showCommuteDays={true} rideDays={props.selectedPlan.days} routeDetails={{
              originAddress: addressDetails.originAddress,
              destinationAddress: addressDetails.destinationAddress,
              matchedOriginStop: {
                departureTimestamp: addressDetails.originDepartureTime,
                arrivalTimestamp: addressDetails.originArrivalTime
              },
              matchedDestinationStop: {
                departureTimestamp: addressDetails.destinationDepartureTime,
                arrivalTimestamp: addressDetails.destinationArrivalTime
              }
            }} updatePDPopup={() => updatePDPopup(true)} 
            taxes={props.selectedPlan.taxes}
            surcharges={props.selectedPlan.surcharges}
            totalTax={parseFloat(props.selectedPlan.taxAmount)+parseFloat(props.selectedPlan.surchargeAmount)}/>
            <div className="vanpool-summary-image">
              <div className="van-Image">
                <div className='car-image'>
                <img src={van.vanpoolImage} onError={e => {
                  e.currentTarget.src = ImageNotAvailable;
                  e.currentTarget.className = "image-not-found";
                  }} alt='van image' />
                </div>
              </div>
            </div>
            <div className="vanpool-summary-plan">
              <DatePickerCustom selectedDay={selectedDay} days={plan} toggleCalendar={toggleCalendar} onCalendarKeydown={onCalendarKeydown} placeholder='Select Start Date' label='Select a start date for your new subscription plan*' />
            </div>
            <SummaryPaymentCards cards={cards} />
          </div>}
        <div aria-live='polite' role='alert' id='modal' className={"popup" + (isOpen ? " show" : " hide")}>
          <Calendar
            reference={node}
            buttonText={'confirm start date'}
            toggle={toggleCalendar}
            selectedDay={selectedDay}
            onConfirm={updateSelectedDay}
            startDay={calendarInfo.startDay}
            endDay={calendarInfo.endDay}
            reset={isOpen}
            enabledDays={enabledDays}
          />
        </div>
        <div className='button-container'>
          <button className='save-button' onClick={saveChangeSubscription} disabled={!selectedDay}>continue</button>
        </div>
        <DiscardChanges
          heading='Unsaved Changes'
          text='Are you sure you want to continue? Any unsaved changes will be lost.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, continue'
          when={true}
          navigate={path => props.history.replace(path)}
          shouldBlockNavigation={location => {
            if (!!selectedDay && (!props.changed || props.changed === null)) {
              if (location.pathname !== '/myprofile/change/plan/summary') {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }}
          handleValidation={() => {}}
          reference={navigationAlertNode}
        />
      </div>
      {
          showPDPopup &&  <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
      }
      {
        showConfirmation && <ChangeSubscriptionConfirmation startDay={subscriptionStartDay} close={closeChangeSubscriptionPopup} />
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedPlan: state.profile.selectedPlan,
    profile: state.profile,
    changed: state.profile && state.profile.subscription && state.profile.subscription.typeChanged,
    loaded: state.profile.isLoaded,
    agreementId: state.joinCommute.contractInfo.agreementId,
    clickwrapId: state.joinCommute.contractInfo.clickwrapId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeSubscription(data) {
      dispatch({ type: actionTypes.CHANGE_SUBSCRIPTION_TYPE, data });
    },
    changeSubscriptionReset() {
      dispatch({ type: actionTypes.RESET_CHANGE_SUBSCRIPTION_TYPE });
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubscriptionSummary);